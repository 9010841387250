<template>
	<nav
		id="navigation"
		:class="{
			'clickthrough' : viewportIsBiggerThanNavigation
		}"
	>
		<transition-group
			:css="false"
			class="navigation-bar"
			tag="div"
			appear
			@before-enter="transitionNavigationEnter"
		>
			<router-link
				v-for="(navigationItem, navigationItemIndex) in navigationItems"
				:key="`navigation-item-${navigationItem.name}`"
				:to="{ path: navigationItem.link }"
				class="bubble bubble-black navigation-item"
				:data-index="navigationItemIndex"
			>
				{{ navigationItem.name }}
			</router-link>
		</transition-group>
	</nav>
</template>

<script>
	import anime from 'animejs/lib/anime.es.js';

	export default ({
		data() {
			return {
				body: null,
				navigationBar: null,
				viewportIsBiggerThanNavigation: null,
				navigationIsDown: false,
				navigationStartX: 0,
				navigationScrollLeft: 0,
				navigationItems: [
					{
						name: 'Eureka!',
						link: '/'
					},
					{
						name: 'Leistungen',
						link: '/leistungen'
					},
					{
						name: 'Kontakt',
						link: '/kontakt'
					}
				]
			}
		},
		methods: {
			checkWidthOfNavigation() {
				window.requestAnimationFrame(() => {
					setTimeout(() => {
						let paddingOfNavigationHolder = parseInt(getComputedStyle(document.querySelector('#navigation')).paddingLeft)
						let navigationWidth = document.querySelector('.navigation-bar').offsetWidth + paddingOfNavigationHolder + paddingOfNavigationHolder
						let viewportWidth = document.documentElement.clientWidth || window.innerWidth

						if (viewportWidth > navigationWidth) {
							this.viewportIsBiggerThanNavigation = true
							this.navigationBar.classList.remove('drag-me')
						} else {
							this.viewportIsBiggerThanNavigation = false
							this.navigationBar.classList.add('drag-me')
						}
					},1000)
				})
			},

			transitionNavigationEnter(el, done) {
				let itemStaggeringAnimation = el.dataset.index * 50

				anime({
					targets: el,
					translateY: ['50%', '0%'],
					opacity: [0, 1],
					duration: 1000,
					delay: itemStaggeringAnimation + 1000,
					easing: 'easeOutElastic(1, .75)',
					complete: done
				})
			}
		},

		mounted() {
			// Drag menu functionality
			this.navigationBar = document.querySelector('.navigation-bar')

			// Check if navigation is bigger then viewport
			this.checkWidthOfNavigation()
			window.addEventListener('resize', this.checkWidthOfNavigation)

			this.navigationBar.addEventListener('mousedown', event => {
				// If viewport is bigger then animation, don’t start the function
				if (this.viewportIsBiggerThanNavigation) return
				this.navigationIsDown = true
				this.navigationBar.classList.add('dragging-navigation')
				this.navigationStartX = event.pageX - this.navigationBar.offsetLeft
				this.navigationScrollLeft = document.querySelector('#navigation').scrollLeft
			})

			this.navigationBar.addEventListener('mouseleave', () => {
				this.navigationIsDown = false
				this.navigationBar.classList.remove('dragging-navigation')
				this.navigationBar.querySelectorAll('.navigation-item').forEach(item => {
					item.classList.remove('dragging-navigation-item')
				})
			})

			this.navigationBar.addEventListener('mouseup', () => {
				this.navigationIsDown = false
				this.navigationBar.classList.remove('dragging-navigation')
				this.navigationBar.querySelectorAll('.navigation-item').forEach(item => {
					item.classList.remove('dragging-navigation-item')
				})
			})

			this.navigationBar.addEventListener('mousemove', event => {
				// If viewport is bigger then animation, don’t start the function
				if (this.viewportIsBiggerThanNavigation || !this.navigationIsDown) return
				this.navigationBar.querySelectorAll('.navigation-item').forEach(item => {
					event.preventDefault
					item.classList.add('dragging-navigation-item')
				})

				const xMovement = event.pageX - this.navigationBar.offsetLeft
				const moved = xMovement - this.navigationStartX

				document.querySelector('#navigation').scrollLeft = this.navigationScrollLeft - moved
			})
		},

		destroyed () {
			window.removeEventListener('resize', this.checkWidthOfNavigation)
		}
	})
</script>

<style scoped lang="scss">

	@import '../assets/css/variables.css';
  	@import '../assets/css/scss-variables.scss';

	.bubble {
		cursor: pointer;
		/*transition: .1s ease;*/
		/* fixes ugly scaling on hover */
		will-change: transform;

		background: var(--black);
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}

	#navigation {
		--spacer-navigation: calc(var(--spacer-padding) * 1.5);
		--speech-bubble-sizer: 0.4em;

		display: flex;

		position: fixed;
		padding: var(--spacer-padding) var(--spacer-navigation) var(--spacer-navigation) var(--spacer-navigation);
		bottom: 0;
		right: 0;
		left: 0;

		z-index: 50;

		overflow-x: auto;
		overflow-y: visible;
 		white-space: nowrap;

		@media (pointer: fine) {
			bottom: unset;
			top: 0;
		}

		@media (min-width: $mediaquery-tablet-landscape) {
			bottom: unset;
			top: 0;
		}


		&.clickthrough {
			pointer-events: none;
		}

		&::-webkit-scrollbar {
			width: 0 !important;
			height: 0 !important;
			display: none;
		}

		.navigation-bar {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
			margin: 0 auto;
			gap: .5em;
			pointer-events: all;

			&.drag-me {
				cursor: move; /* fallback if grab cursor is unsupported */
				cursor: grab;
				cursor: -moz-grab;
				cursor: -webkit-grab;

				.navigation-item {
					cursor: inherit;
				}

				&.dragging-navigation {
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}

			.navigation-item {
				display: flex;
				align-self: flex-end;
				flex: 0 0 auto;
				font-weight: 800;
				margin-bottom: 0;
				// transition: transform .05s ease-in-out;

				@media (pointer: fine) {
					&:hover {
						// transform: translateY(-15%) scale(1.025) rotate(-3deg);
						transform: translateY(-15%);
					}
				}

				&:active {
					color: #d3d3d3 !important;
					box-shadow: var(--box-shadow-small), inset 0 0 5px var(--black);
					background: -webkit-gradient(linear, left top, left bottom, from(var(--black)), to(#444));
					cursor: pointer !important;
				}

				&.dragging-navigation-item,
				&.dragging-navigation-item a {
					pointer-events: none;
				}

				&.router-link-exact-active {
					text-decoration: underline;
					transform: scale(1.1);
				}

				&:first-of-type {
					position: relative;

					&:after {
						content: "";
						position: absolute;
						// left: calc(var(--speech-bubble-sizer) * 1.3);
						left: calc(var(--extra-radius) * 1.25);
						bottom: 1%;
						width: 0;
						height: 0;
						border-left: 0px solid transparent;
						border-right: calc(var(--speech-bubble-sizer) * 1.5) solid transparent;
						border-top: calc(var(--speech-bubble-sizer) * 1.5) solid var(--darkgray);
						clear: both;
						z-index: -10;
						transform: translateY(100%);
					}
				}
			}
		}
	}

</style>
